import React, { useState } from "react";
import style from "./CashFlow.module.css";
import groupImg from "../../assets/images/group.png";
const Data = [
  {
    name: "SUPLLEMENT CASH FLOW",
    top: "We at Bbdg help u provide information about all investing and financing activities of an enterprise. ",
    mid: "During a period that affect recognized assets or liabilities however should not result in cash receipts or cash payments in the period. Join in with us today to ease your supplemental Cash Flow.",
    points: [
      "Purchase equipment",
      "software",
      // "The ability to connect up to 32 times clocks",
      "or hardware without using cash you need for everyday activities",
    ],
    image: { groupImg },
  },
  {
    name: "SELL ONLINE",
    top: "We at BBDG provides u business opportunities to learn about managing an online business and attracting customers.",
    mid: "BBDG helps your customers to find your utmost potential and invest in you to make u standout in the business world. ",
    points: [
      "upgrade and enhance your website",
      "hiring a consultant as well as running marketing campaigns ",
      "follow along purchasing inventory"
    ],
    image: { groupImg },
  },
  {
    name: "COVER EXPENSES",
    top: "At BBDG, we help u build methadologies to manage your business and  cover its expenses along with to  streamline the business outcomes through guidance and support from top industry leaders. ",
    mid: "We ensure conscious and highly profitable cover expenses. The key audience we aim at includes",
    points: [
      "pay suppliers",
      "landlords and",
      "employees",
    ],
    image: { groupImg },
  },
];

function CashFlow() {
  const [CurrentSection, setCurrentSection] = useState(Data[0]);
  return (
    <>
      <div className={style.Industries} id="Industries">
        <h1>WE WORK WITH INDUSTRIES</h1>

        <div className={style.IndustriesButton}>
          {Data.map((section, index) => {
            return (
              <button
                key={index}
                className={section.name ? style.IndustriesButtonActive : ""}
                onClick={(e) => {
                  setCurrentSection(Data[index]);
                }}
              >
                {section.name}
              </button>
            );
          })}
        </div>
        <div className={style.IndustriesContent}>
          <img src={CurrentSection.image.groupImg} alt="currentImg" />
          <div className={style.IndustriesContentText}>
            <div className={style.IndustriesContentTexta}>
              <p>{CurrentSection.top}</p>
            </div>
            <div className={style.IndustriesContentTextb}>
              <p>{CurrentSection.mid}</p>
            </div>
            <ol className={style.IndustriesContentTextcol}>
              {CurrentSection.points.map((li, index) => {
                return (
                  <li key={index} className={style.IndustriesContentTextcolLi}>
                    {li}
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
      </div>
    </>
  );
}

export default CashFlow;
