import { useEffect, useState } from "react";
import style from "./id.module.css";
import Banner from "../../../../helpers/Banner/Banner";
import BannerStyle from "../../../../helpers/Banner/Banner.module.css";
import axios from "axios";
import { BaseApi, ImageBaseUrl } from "../../../../utils/utils";
import { useLocation } from "react-router";
import ReactMultiCarousel from "react-multi-carousel";

function Index() {
  const { pathname } = useLocation();
  const urlArray = pathname.split("/");
  const tab = urlArray[urlArray.length - 1].split("%20").join(" ");
  const [mainIndex, setMainIndex] = useState(0);
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const portfolioResponse = await axios.get(`${BaseApi}/portfolio/all`);
      const portfolio = await portfolioResponse.data;
      setData(portfolio.data);
    };
    fetchData();
    const i = data && data.findIndex((item) => item.name.toLowerCase() === tab);
    if (i === -1) {
      setMainIndex(0);
    } else {
      setMainIndex(i);
    }
  }, [data, tab]);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <Banner>
        <p className={BannerStyle.smallHeading}>
          SERVICES <i className="fas fa-chevron-right"></i>
          IT SOLUTIONS
          <i className="fas fa-chevron-right"></i>PORTFOLIO
          <i className="fas fa-chevron-right"></i>
          <span className={BannerStyle.lowOpacity}>
            {data[mainIndex]?.name}
          </span>
        </p>

        <p className={BannerStyle.BigHeading}>{data[mainIndex]?.name} </p>
        <p className={BannerStyle.midHeading}>
          <br></br>
          HAVE A LOOK AT OUR
          <br></br>
          EXPANDED PORTFOLIO OF
          <br></br>
          PROJECTS THAT WE HAVE COMPLETED
        </p>
      </Banner>
      <div className={style.mainDiv}>
        <h1 className={style.heading}>our work showcase</h1>
        <div className={style.title}>{data[mainIndex]?.name}</div>
        <div className={style.CarouselWraper}>
          <ReactMultiCarousel
            responsive={responsive}
            className={style.Carousel}
            dotListClass={style.CarouselDotListClass}
            autoPlaySpeed={6000}
            infinite
            showDots
            autoPlay
            centerMode
            renderDotsOutside
          >
            {data[mainIndex]?.contents?.map((item) => (
              <div className={style.CarouselItemWrapper}>
                <div className={style.CarouselItem}>
                  <div className={style.CarouselItemHover}></div>
                  <img
                    src={ImageBaseUrl + item.image}
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
            )) || <></>}
          </ReactMultiCarousel>
        </div>
        <p className={style.textData}>
          {data[mainIndex]?.contents[0]?.text_data}
        </p>
      </div>
    </>
  );
}

export default Index;
