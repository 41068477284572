import style from "./carousel.module.css";
import { useNavigate } from "react-router";
import webDevelopmentVideo from "../../assets/videos/web development .mp4";
import softwareVideo from "../../assets/videos/software .mp4";
import appsVideo from "../../assets/videos/apps .mp4";
import UIUXVideo from "../../assets/videos/UIUX .mp4";
import prototypeVideo from "../../assets/videos/prototype .mp4";
import "react-multi-carousel/lib/styles.css";

const videos = [
  softwareVideo,
  webDevelopmentVideo,
  appsVideo,
  prototypeVideo,
  UIUXVideo,
];

function Carousel({ title, data }) {
  const navigate = useNavigate();
  return (
    <>
      <div className={style.mainDiv}>
        <h1>{title}</h1>
        <div className={style.wrapper}>
          {data?.map((heading, i) => (
            <div
              key={i}
              className={style.box}
              onClick={() =>
                navigate(`/services/it-solutions/portfolio/${heading.name}`)
              }
            >
              <div className={style.title}>{heading.name}</div>
              <video loop autoPlay muted className={style.video}>
                <source src={videos[i]} type="video/mp4" />
              </video>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Carousel;
