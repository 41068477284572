import Banner from "../../../helpers/Banner/Banner";
import BannerStyle from "../../../helpers/Banner/Banner.module.css";
import axios from "axios";
import { BaseApi } from "../../../utils/utils";
// import CenterText from "../../components/Text/CenterText";
import Markdown from "markdown-to-jsx";
import LayoutStyle from "../../../helpers/layout/layout.module.css";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

const SubSection = () => {
  const fullPath = useParams();
  console.log(fullPath)
  const [parentDetails, setParentDetails] = useState({})
  const [parentHeading ,setParentHeading] = useState("")
  useEffect(() => {
    const fetchData = async () => {
      axios.get(`${BaseApi}/about/${fullPath.id}`)
        .then(res => {
          console.log(res)
          setParentDetails(res.data.data[0])
          setParentHeading(res.data.data[0].heading)
        })
        .catch(err => {
          // navigate("/")
          console.log(err)
        })
    };
    fetchData();
  }, []);
  // const parentDetails = post.data.parent;
  // const subServices = post.data.allChildren;
  // const parentHeading = post.slug[post.slug.length - 1].split("-").join(" ");
  // const parentHeading = "ll"
  const RouteCreater = (routeArray) => {
    var route = `services <i class='fas fa-chevron-right'></i> `;
    for (let i = 0; i < routeArray.length; i++) {
      const element = routeArray[i].split("-").join(` `);
      if (i === routeArray.length - 1) {
        route =
          route +
          `<span class=${BannerStyle.lowOpacity}>` +
          element +
          "</span>";
      } else {
        route = route + element + " <i class='fas fa-chevron-right'></i> ";
      }
    }
    return route;
  };

  const Content =  parentDetails ? parentDetails.text : "";
  console.log(parentDetails);

  return (
    <>
      <Banner>
        <p
          className={BannerStyle.smallHeading}
        >ABOUT <i className='fas fa-chevron-right'></i> CAREERS <i className='fas fa-chevron-right'></i> <span class={BannerStyle.lowOpacity}>{parentHeading}</span></p>
        <p className={BannerStyle.BigHeading}>{parentHeading}</p>
        {console.log(parentDetails)}
        <p className={BannerStyle.midHeading}>{parentDetails ? parentDetails.subHeading :""}</p>
      </Banner>

      {(parentDetails && parentDetails.text != null) && (
        <Markdown className={LayoutStyle.markDownStyle}>{Content}</Markdown>
      )}
    </>
  );
};

export default SubSection;
